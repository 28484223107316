<template>
  <div
    class="my-5 py-5"
    :style="{
      backgroundImage:
        'url(' + require('@/assets/images/background-login.jpg') + ')'
    }"
    id="forgotPasswordArea"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="auth-wrapper">
          <div class="card overflow-hidden" style="background: transparent">
            <div class="card-title card-header px-0">
              <img src="@/assets/logo-dark.png" height="50" />
            </div>
            <div class="card-sub-title d-flex align-items-center">
              Por favor, entre com seu usuário para receber instruções de como
              recuperar sua senha.
            </div>
            <div class="card-body bg-white" v-if="!success">
              <b-alert v-model="isAuthError" variant="danger" dismissible>
                <div>{{ authError }}</div>
              </b-alert>

              <b-form @submit.prevent="recoverPassword">
                <b-form-group
                  id="email-input-group"
                  label="Usuário"
                  label-for="email-input"
                >
                  <b-form-input
                    id="email-input"
                    v-model="email"
                    type="text"
                    placeholder="Nome de usuário ou endereço de e-mail"
                    :class="{ 'is-invalid': submitted && $v.email.$error }"
                  />

                  <div
                    v-if="submitted && $v.email.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.email.required"> Campo obrigatório </span>
                    <span v-if="!$v.email.email">
                      Por favor, digite um e-mail válido
                    </span>
                  </div>
                </b-form-group>

                <div class="mt-3 text-center">
                  <b-button
                    pill
                    type="submit"
                    variant="primary"
                    class="font-weight-bold"
                    style="padding: 12px 62px"
                    v-if="!loading"
                  >
                    Recuperar senha
                  </b-button>

                  <b-spinner
                    variant="primary"
                    label="Carregando"
                    class="mt-1"
                    v-if="loading"
                  />
                </div>

                <div class="mt-4 text-center">
                  <router-link :to="{ name: 'login' }" class="text-muted">
                    Acessar minha conta
                  </router-link>
                </div>
              </b-form>
            </div>

            <div class="card-body bg-white" v-if="success">
              {{ successMessage }}
            </div>
          </div>

          <div class="text-center">
            <p style="display: none">
              Não tem conta?
              <a
                target="_blank"
                href="http://cadastro.unico.segasp-univalores.com.br/"
              >
                Quer ser um CORRETOR SEGASP
              </a>
            </p>
            <p>&copy; {{ actualYear }} - SEGASP Univalores</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';

const { mapActions } = createNamespacedHelpers('auth');
export default {
  name: 'ForgotPasswordPage',
  data() {
    return {
      email: '',
      submitted: false,
      authError: null,
      loading: false,
      isAuthError: false,
      success: false,
      successMessage: null
    };
  },
  mixins: [validationMixin],
  validations: {
    email: { required }
  },
  methods: {
    ...mapActions(['forgotPassword']),
    recoverPassword() {
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) return;

      this.loading = true;

      this.forgotPassword({ login: this.email })
        .then((_response) => {
          this.success = true;
          this.successMessage = _response;
        })
        .catch((_error) => {
          this.authError = _error;
          this.isAuthError = true;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/design/index.scss';

.auth-wrapper {
  width: 90%;
  max-width: 450px;
}

.card-header {
  background: $sidebar-dark-bg;
  text-align: center;
  padding: 20px;
}
</style>

<style>
#forgotPasswordArea {
  margin: 0 !important;
  min-height: 100vh;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: right top;
}

#forgotPasswordArea .card-title {
  background: transparent;
  margin: 0;
}

#forgotPasswordArea .card-sub-title {
  height: 100px;
  margin: 0px;
  padding: 20px !important;

  color: #fff;
  background-color: var(--primary);
}
</style>
